import classes from './PopisSluzieb.module.css'

const PopisSluzieb = () => {
    return ( 
        <div className = {classes.PopisSluzieb} id="PopisSluzieb">
            <div className = {classes.content}>
                <h1>Psychologické konzultácie</h1>
                <p>Psychologické konzultácie zahŕňajú popísanie toho, <span style={{fontWeight: 'bold'}}>ako sa aktuálne máte</span>, pomenovanie toho, čo Vás <span style={{fontWeight: 'bold'}}>trápi</span> a v čom by ste <span style={{fontWeight: 'bold'}}>potrebovali pomôcť</span>. Či ide o náročné myšlienky, krízové situácie (suicidálne myšlienky, sebadeštruktívne myšlienky, stratu blízkej osoby, psychické, fyzické a sexuálne násilie), sexuálnu orientáciu, úzkosti, depresie, smútok či pocity osamelosti, beznádeje, nespavosť atď. Konzultácia je <span style={{fontWeight: 'bold'}}>nástroj k pomenovaniu</span> toho, čo aktuálne prežívate a <span style={{fontWeight: 'bold'}}>k zorientovaniu sa</span> vo Vašej situácii. Zároveň konzultácia prináša pocit <span style={{fontWeight: 'bold'}}>upokojenia, stabilizáciu emócií a konkrétne kroky</span>, ktoré môžete vo svojom živote aplikovať, aby ste sa každým krokom dopredu cítili aspoň o trošku <span style={{fontWeight: 'bold'}}>lepšie</span>.<br/> Pri psychologických konzultáciách sa riadim niekoľkými základnými princípmi:</p>
                <ul>
                    <li><p style={{display:"inline"}}><span style={{fontWeight: 'bold'}}>prijatie</span> - nech sa u Vás deje čokoľvek, nech čokoľvek prežívate, som tu pre Vás a rada v tom celom budem spolu s Vami</p></li>
                    <li><p style={{display:"inline"}}><span style={{fontWeight: 'bold'}}>podpora</span> - počas celého obdobia, počas ktorého sa budeme stretávať, máte z mojej strany istú podporu</p></li>
                    <li><p style={{display:"inline"}}><span style={{fontWeight: 'bold'}}>priateľstvo</span> - aby pri našom stretnutí bola príjemná, dôverná atmosféra</p></li>
                    <li><p style={{display:"inline"}}><span style={{fontWeight: 'bold'}}>profesionalita</span> - profesionalitu zaručuje moje vzdelanie, skúsenosti a moja pravidelná účasť na supervíziách</p></li>
                    <li><p style={{display:"inline"}}><span style={{fontWeight: 'bold'}}>dobrovoľnosť</span> - naše stretnutia sú vždy o dobrovoľnosti z Vašej strany; je len a len na Vás, či sa rozhodnete prísť alebo nie</p></li>
                    <li><p style={{display:"inline"}}><span style={{fontWeight: 'bold'}}>dôvernosť</span> - všetko s čím sa mi zdôveríte ostáva len medzi nami.</p></li>
                </ul>
                <br/>
                <p>V tom, čo prežívate <span style={{fontWeight: 'bold'}}>nemusíte zostať osamote</span>. Som tu pre Vás.</p>
                <br/>
                <br/>
                <h1>Koučing</h1>
                <p>Koučing pomáha z Vás samých dostať len to <span style={{fontWeight: 'bold'}}>najlepšie</span>. Vďaka tomu je označovaný ako metóda, ktorá je jedinečná jej unikátnou čistotou. Zároveň je aj povzbudivou metódou rastu, ktorá sa uplatňuje k žiadúcej zmene správania, dosahovania konkrétnych cieľov, optimalizácie efektivity a výkonu. Jednoducho sa dá povedať, že koučing Vám zabezpečuje <span style={{fontWeight: 'bold'}}>celkový rozvoj</span>. Tak isto aj rozširuje Vaše uvedomenie, pomáha Vám stanoviť si vhodné ciele, zorientovať sa vo svojej situácii, prevziať zodpovednosť a naplánovať si konkrétne kroky, aby ste mohli zmeniť svoj život k lepšiemu.<br/> Pri koučovacom procese využívam <span style={{fontWeight: 'bold'}}>dialóg</span> s vlastnou štruktúrou a špecifickými otázkami, <span style={{fontWeight: 'bold'}}>koučovacími metódami a technikami</span>, ktoré sa dajú využiť pri <span style={{fontWeight: 'bold'}}>rôznych situáciách</span>, v ktorých sa v živote môžete ocitnúť. Či ide napr. o riešenie vzťahov, akékoľvek rozhodovanie sa, o zmenu v živote, výkonnosť v práci či získanie energie atď. Počas koučovacieho procesu sa riadim niekoľkými <span style={{fontWeight: 'bold'}}>princípmi</span>, ktoré sú základom celého koučingu:</p>
                <ul>
                    <li><p style={{display:"inline"}}>Orientujem sa na Vašu <span style={{fontWeight: 'bold'}}>budúcnosť a prítomnosť</span> - kým ste teraz a kým by ste sa chceli stať v budúcnosti</p></li>
                    <li><p style={{display:"inline"}}>Dôverujem vo Váš <span style={{fontWeight: 'bold'}}>potenciál</span> a Vašu schopnosť <span style={{fontWeight: 'bold'}}>učiť sa</span> - dôverujem, že môžete dosiahnuť všetko, po čom túžite</p></li>
                    <li><p style={{display:"inline"}}>Orientujem sa na <span style={{fontWeight: 'bold'}}>riešenie</span>, nie na problém - sústreďujem sa na to ako si to prajete mať</p></li>
                    <li><p style={{display:"inline"}}>Vytvorím <span style={{fontWeight: 'bold'}}>bezpečné prostredie</span> pre Váš rozvoj - aby ste mohli so mnou zdieľať čokoľvek si prajete</p></li>
                    <li><p style={{display:"inline"}}><span style={{fontWeight: 'bold'}}>Zodpovednosť</span> za rozhodnutie je vždy na Vašej strane - pomocou techník Vám budem pomáhať k vlastnému uvedomeniu si riešenia, ktoré je to najlepšie práve pre Vás.</p></li>
                </ul>
                <br/>
                <p>Vďaka koučingu tak môžete využiť to, čo už máte dávno v sebe, svoj <span style={{fontWeight: 'bold'}}>potenciál</span>.</p>
            </div>
        </div> 
    );
}
 
export default PopisSluzieb;