import classes from './Cennik.module.css'
import { useState, useEffect } from 'react';
const Cennik = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    useEffect(() => {
    const handleWindowResize = () => {
        setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
    });

    return ( 
        <div className = {classes.Cennik} id="Cennik">
            <div className = {classes.content}>
                <h1>Cenník</h1>
                {windowSize[0]<550 ? <>
                    <div className={classes.miniRiadok}>
                        <p className={classes.miniPar}>Psychologická konzultácia - 50min</p> 
                        <p> 40€</p>                   
                    </div>
                    <div className={classes.miniRiadok}>
                        <p className={classes.miniPar}>Koučing - 50min</p> 
                        <p> 40€</p>                   
                    </div>
                    <div className={classes.miniRiadok}>
                        <p className={classes.miniPar}>Online psychologická konzultácia - 50min</p> 
                        <p> 40€</p>                   
                    </div>
                    <div className={classes.miniRiadok}>
                        <p className={classes.miniPar}>Online koučing - 50min</p> 
                        <p> 40€</p>                   
                    </div>
                    
                
                </> : <>
                {windowSize[0]<800 ? <p>Psychologická konzultácia - 50min...................... 40€</p> : <p>Psychologická konzultácia - 50min................................................................... 40€</p>}
                {windowSize[0]<800 ? <p>Koučing - 50min...................................................... 40€</p>: <p>Koučing - 50min................................................................................................... 40€</p>}
                {windowSize[0]<800 ? <p>Online psychologická konzultácia - 50min......... 40€</p>: <p>Online psychologická konzultácia - 50min...................................................... 40€</p>}
                {windowSize[0]<800 ? <p>Online koučing - 50min.......................................... 40€</p>: <p>Online koučing - 50min...................................................................................... 40€</p>}   
                </>}
            </div>
        </div> 
    );
}
 
export default Cennik;