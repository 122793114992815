import NavBar from "./components/NavBar/NavBar";
import Omne from "./components/Omne/Omne";
import Objednanie from "./components/Objednanie/Objednanie";
import PopisSluzieb from "./components/PopisSluzieb/PopisSluzieb";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"
import Cennik from "./components/Cennik/Cennik";
import Adresa from "./components/Adresa/Adresa";
import Vzdelanie from "./components/Vzdelanie/Vzdelanie";
import Tesim from "./components/Tesim/Tesim";
import Uvod from "./components/Uvod/Uvod";
import Delic from "./components/Delic/Delic";
import InstagramFeed from "./components/InstagramFeed/InstagramFeed";
const App = () => {
  return (
    <div className="App">
      <Uvod />
      <Delic />
      <Omne />
      <Delic typ={2}/>
      <PopisSluzieb />
      <Delic />
      <Objednanie />
      <Delic typ={2}/>  
      <Cennik />   
      <Delic /> 
      <Adresa />
      <Delic typ={2}/> 
      <Vzdelanie />
      <Delic /> 
      <InstagramFeed />
      <Delic typ={2}/> 
      <Tesim />
      <ScrollToTop />
      <NavBar />
    </div>
  );
}

export default App;
