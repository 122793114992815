import classes from './NavBar.module.css'
import linkedinIcon from './../../img/linkedinIcon.png';
import MobileNavigation from './MobileNavigation';
import emccIcon from './../../img/emccIcon.png';
import igIcon from './../../img/igIcon.png';
const NavBar = () => {
    return ( 
        <div className = {classes.NavBar} id="uvod">
            <div className={classes.menuPozadie}>
                <MobileNavigation />  
                <div className={classes.pomocny}></div>
                <a className={classes.buttonLink} rel="noreferrer" href="https://www.linkedin.com/in/lucia-hl%C3%ADstov%C3%A1-609925256/?fbclid=IwAR2OWBP8vuOO9n667cRaQCt-lmCIemNpg8OkbDhcftQcjZn9wShbi8wfJms" target="_blank">
                    <img className={classes.linkedinIcon} src={linkedinIcon} alt="LinkedIn Icon" />
                </a>
                <a className={classes.buttonLink} rel="noreferrer" href="https://www.instagram.com/_.lanavi._/" target="_blank">
                    <img  className={classes.igIcon} src={igIcon} alt="Instagram Icon" />
                </a>
                <a className={classes.buttonLink} rel="noreferrer" href="https://www.emccczech.cz" target="_blank">
                    <img  className={classes.emccIcon} src={emccIcon} alt="EMCC Icon" />
                </a>
            </div>
        </div> 
    );
}
 
export default NavBar;