import classes from './Adresa.module.css'
import locationIcon from './../../img/locationIcon.png'; 
import onlineIcon from './../../img/onlineIcon.png'; 
import buildingImgPng from './../../img/building.png';
import buildingWebp from './../../img/building.webp';
const Adresa = () => {
    return ( 
        <div className = {classes.Adresa} id="Adresa">
            <div className = {classes.content}>
                <div>
                    <h1>Adresa</h1>
                    <div className = {classes.adresaRiadok}>
                        <img className = {classes.locationIcon} src={locationIcon} alt="location Icon" />
                        <p>Krajinská 2928/3, Piešťany 921 01</p>
                    </div>
                    <div className = {classes.adresaRiadok}>
                        <img className = {classes.onlineIcon}  src={onlineIcon} alt="mail Icon" />
                        <p>Online - výber platformy dohodou</p>
                    </div>
                </div>
                <div className={classes.kdeNasNajdeteMapa}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2639.162560370874!2d17.8208224!3d48.5875862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476b548bac9edc2d%3A0x5144764869dac8b9!2zS3Jhamluc2vDoSAyOTI4LzMsIDkyMSAwMSBQaWXFocWlYW55LCBTbG92ZW5za28!5e0!3m2!1ssk!2sch!4v1673055934598!5m2!1ssk!2sch" 
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade"
                            title="map"></iframe>
                </div>
                <picture>
                    <source type="image/webp" srcSet={buildingWebp} />
                    <source type="image/png" srcSet={buildingImgPng} />
                    <img className = {classes.building} src={buildingImgPng} alt="our building" />
                </picture>
            </div>
        </div> 
    );
}
 
export default Adresa;