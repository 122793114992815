import classes from './Vzdelanie.module.css'

const Vzdelanie = () => {
    return ( 
        <div className = {classes.Vzdelanie} id="Vzdelanie">
            <div className = {classes.content}>
                <h1>Moje vzdelanie a pracovné skúsenosti</h1>
                <div className = {classes.podNadpisom}>
                    <div>
                        <h2><span style={{fontWeight: 'bold'}}>Vzdelanie:</span></h2> 
                        <p>Trnavská univerzita v Trnave - štúdium jednoodborovej psychológie</p>
                        <p>Akreditované vzdelávanie v krízovej intervencii</p>
                        <p>Vzdelávanie v práci so skupinovou dynamikou</p>
                        <p>Vzdelávanie pre pomoc obetiam sexuálneho zneužívania</p>
                        <p>Vzdelávanie ohľadne pomoci pri sebapoškodzovaní</p>
                        <p>Vzdelávanie k práci s traumou</p>
                        <p>Základy smútkového poradenstva</p>
                        <p>Špecifiká psychologickej pomoci COVID-19</p>
                        <p>Koučovací výcvik</p>
                    </div>
                    <div>
                        <h2><span style={{fontWeight: 'bold'}}>Pracovné skúsenosti:</span></h2>
                        <p>Pomoc obetiam násilia</p>
                        <p>IPčko - Krízová linka pomoci</p>
                    </div>
                </div>
            </div>
        </div> 
    );
}
 
export default Vzdelanie;