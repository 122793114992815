import classes from './Delic.module.css'

const Delic = ({typ}) => {
    return ( 
        <div className = {classes.Delic} id="Delic">
            <div className = {classes.content}>
                {typ === 2 ?
                    <svg width="3090" height="123" viewBox="0 0 3090 123" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.348999 95.0083L3089.43 28.3562" stroke="#CAB59A" stroke-width="6"/>
                        <path d="M0.356323 62.2513L3089.79 73.6532" stroke="#3B4A6B" stroke-width="6"/>
                    </svg>
                :
                    <svg width="3094" height="86" viewBox="0 0 3094 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.48108 3L3093.26 82.7277" stroke="#CAB59A" stroke-width="6"/>
                        <path d="M1 39.3076L3090.43 50.7094" stroke="#3B4A6B" stroke-width="6"/>
                    </svg>  
                }
            </div> 
        </div> 
    );
}
 
export default Delic;