import { useEffect } from 'react';
import classes from './InstagramFeed.module.css'
import { useState } from 'react';
const InstagramFeed = () => {

    const [instagramData, setInstagramData] = useState([]);
    const accessToken = 'IGQWRPcEJrbWRKOUp3R2ZAGckN3UVU5OExhX3dHd1FOMko3WXhDNk1oQVhzcHNnMmJWU3NmYW5Wc3o2aUZAjUXdicTVTQUYwMWREZAHVhTVZArczlkendYOXFnNGU0aTc3LThLNmIxSDRBV1ZACZAXUtdk1UeW5ZARG5wWjAZD';
    useEffect(() => {
        const getInstagramFeed = async () => {
          try {
            const response = await fetch(`https://graph.instagram.com/v18.0/me/media?fields=id,caption,media_type,media_url,thubnail_url,permalink,timestamp&access_token=${accessToken}`);
            const data = await response.json();
            setInstagramData(data.data.slice(0, 8));
          } catch (error) {
          }
        };
    
        getInstagramFeed();
      }, [accessToken]);
    


    return ( 
        <div className = {classes.content}>
            <h1>Zisti viac na instagrame <a className={classes.IgOdkaz} href="https://www.instagram.com/_.lanavi._/">@_.lanavi._</a></h1>
            <div className = {classes.InstagramFeed}>
                {instagramData.map(post => (
                    <a key={post.id} href={post.permalink} target="_blank" rel="noopener noreferrer">
                    {post.media_type === 'VIDEO' ? (
                        <video controls disablePictureInPicture controlslist="nodownload noplaybackrate">
                            <source src={post.media_url} type="video/mp4" />
                        </video>
                    ) : (
                        <img src={post.media_url} alt={"fotka z instagramu"}/>
                    )}
                    </a>
                ))}
            </div> 
        </div>
    );
}
 
export default InstagramFeed;