import exclamationIcon from './../../img/exclamationIcon.png';
import mailIcon from './../../img/mail.png';
import telIcon from './../../img/tel.png';
import classes from './Objednanie.module.css';

const Objednanie = () => {
    return ( 
        <div className = {classes.Objednanie} id="Objednanie">
            <div className = {classes.content}>
                <h1>Objednanie</h1>
                <div className = {classes.podNadpisom}>
                    <div className={classes.divHlavny}>
                        <p className={classes.ObjdnatSaMozete}>Objednať sa môžete:</p>
                        <div className={classes.divMalyVlavo}>
                            <img className={classes.phoneIcon} src={telIcon} alt="telephone Icon" />
                            <p>Tel. číslo: <span style={{fontWeight: 'bold'}}>+421 949 650 049</span> / pošlite <span style={{fontWeight: 'bold'}}>SMS</span> a ozvem sa Vám ja </p>
                        </div>
                        <div className={classes.divMalyVlavo}>
                            <img className={classes.mailIcon} src={mailIcon} alt="mail Icon" />
                            <p>Email: <span style={{fontWeight: 'bold'}}>lanavihlistova@gmail.com</span></p>
                        </div>
                    </div>
                    <div className={classes.divHlavny}>
                        <div className={classes.divMalyVpravo}>
                            <img className={classes.exclamationIcon} src={exclamationIcon} alt="exclamation Icon" />
                            <p>Garancia termínu do 5 dní</p>
                            <img className={classes.exclamationIcon} src={exclamationIcon} alt="exclamation Icon" />
                        </div>
                        {/* <div  className={classes.divMalyVpravo}>
                            <img className={classes.percentIcon} src={percentIcon} alt="percent Icon" />
                            <p>Každé piate stretnutie so zľavou 30%</p>
                            <img className={classes.percentIcon} src={percentIcon} alt="percent Icon" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div> 
    );
}
 
export default Objednanie;