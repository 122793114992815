import classes from './NavBar.module.css'
import { Link } from "react-scroll";
import {motion} from 'framer-motion'

const NavLinks = (props) => {

    const animateItemFrom = {opacity: 0, x: 40}
    const animateItemTo = {opacity: 1, x: 0}

    const animateDivFrom = {opacity: 1, x: 200}
    const animateDivTo = {opacity: 1, x: 0}

    return ( 
        <motion.ul
        initial={animateDivFrom}
        animate={animateDivTo}
        transition={{delay: 0, restSpeed: 0.5}}
        exit={{
            x:2000,
            opacity:0,
            transition:{duration:0.6}
        }}
        >
            <motion.li 
                initial={animateItemFrom}
                animate={animateItemTo}
                transition={{delay: 0.05}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="Omne" spy={true} smooth={true} offset={-75} duration={500}>O mne</Link>
            </motion.li>
            <motion.li
                initial={animateItemFrom}
                animate={animateItemTo} 
                transition={{delay: 0.10}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="PopisSluzieb" spy={true} smooth={true} offset={-75} duration={500}>Popis služieb</Link>
            </motion.li>
            <motion.li 
                initial={animateItemFrom}
                animate={animateItemTo} 
                transition={{delay: 0.20}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="Objednanie" spy={true} smooth={true} offset={-75} duration={500}>Objednanie</Link>
            </motion.li>
            <motion.li 
                initial={animateItemFrom}
                animate={animateItemTo} 
                transition={{delay: 0.30}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="Cennik" spy={true} smooth={true} offset={-75} duration={500}>Cenník</Link>
            </motion.li>
            <motion.li 
                initial={animateItemFrom}
                animate={animateItemTo} 
                transition={{delay: 0.30}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="Adresa" spy={true} smooth={true} offset={-75} duration={500}>Adresa</Link>
            </motion.li>
            <motion.li 
                initial={animateItemFrom}
                animate={animateItemTo} 
                transition={{delay: 0.30}}
                className={classes.navItem}>
                <Link onClick={() => props.isMobile && props.closeMobileMenu()} to="Vzdelanie" spy={true} smooth={true} offset={-75} duration={500}>Moje vzdelanie a pracovné skúsenosti</Link>
            </motion.li>
        </motion.ul>
    );
}
 
export default NavLinks;